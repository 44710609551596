import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import BookingPage from './pages/BookingPage';
import PlacesPage from './pages/PlacesPage';


function App() {
  return(
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <HomePage /> } />
          <Route path='/About' element={ <AboutUsPage /> } />
          <Route path='/Contact' element={ <ContactPage /> } />
          <Route path='/Booking' element={ <BookingPage /> } />
          <Route path='/Places' element={ <PlacesPage />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
  
}

export default App;

import React from 'react'

import Navbar from "../components/Nav/Nav";
import Places from '../components/Places/Places';
import Footer from "../components/Footer/Footer"

const PlacesPage = () => {
  return (
    <div>
      <Navbar />
        <Places />
      <Footer />
    </div>
  )
}

export default PlacesPage

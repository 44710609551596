import React from 'react'

import Navbar from '../components/Nav/Nav'
import AboutUsBanner from '../components/AboutUs/AboutUsBanner/AboutUsBanner'
import KnowUs from '../components/AboutUs/KnowUs/KnowUs'
import OurStory from '../components/AboutUs/OurStory/OurStory'
import Footer from '../components/Footer/Footer'

const AboutUsPage = () => {
  return (
    <div>
      <Navbar />
      <AboutUsBanner />
      <KnowUs />
      <OurStory />
      <Footer />
    </div>
  )
}

export default AboutUsPage

import React from 'react'

import Navbar from "../components/Nav/Nav"
import Contact from "../components/ContactUs/Contact"
import Footer from "../components/Footer/Footer"

const ContactPage = () => {
  return (
    <div>
      <Navbar />
        <Contact />
      <Footer />
    </div>
  )
}

export default ContactPage

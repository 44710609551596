import React from 'react'

import "./Priorities.css"
import "./PrioritiesMobile.css"

import BedIcon from "../../assets/bedIcon.png"
import coffeeIcon from "../../assets/coffeeIcon.png"
import tvIcon from "../../assets/tvIcon.png"
import fridgeIcon from "../../assets/fridgeIcon.png"
import wifiIcon from "../../assets/wifiIcon.png"
import sofaIcon from "../../assets/sofaIcon.png"

import StrathconaFront from "../../assets/Strathcona-Front.jpg"

const priorities = () => {
  return (
    <div className='priorities-container'>
        <h2>COMFORT - <span>CONVENIENCE</span> - CLEANLINESS</h2>
        <div className="feature-wrapper">
            <div className="feature-left">
                <div className="feature-image"></div>
            </div>
            <div className="feature-right">
                <h2>Welcome to the Strathcona Motel</h2>
                <h4>Your Home Away</h4>
                <p>We take pride in providing our guests with a clean and
                    comfortable stay. When booking with us, you will find that this is 
                    truly a Home Away Experience. We offer an abundance of amenities to 
                    ensure your stay is both comfortable and convenient.
                </p>
                <button><a href="/about">Learn More</a></button>
            </div>
        </div>
        <div className="priorities-wrapper">
            <h3>Every room available includes...</h3>
            <ul>
                <li>
                    <img src={BedIcon} alt="" />
                    <h4>One Double/Queen Size Bed</h4>
                </li>
                <li>
                    <img src={sofaIcon} alt="" />
                    <h4>One Pull Out Sofa</h4>
                </li>
                <li>
                    <img src={wifiIcon} alt="" />
                    <h4>Free Wifi</h4>
                </li>
                <li>
                    <img src={fridgeIcon} alt="" />
                    <h4>Fully Stocked Kitchenette</h4>
                </li>
                <li>
                    <img src={coffeeIcon} alt="" />
                    <h4>Coffee Station</h4>
                </li>
                <li>
                    <img src={tvIcon} alt="" />
                    <h4>Flat Screen TV</h4>
                </li>
            </ul>
        </div>
        <div class="custom-shape-divider-bottom-1662139897">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
</div>

    </div>
  )
}

export default priorities

import React from 'react'

import "./Map.css"
import "./MapMobile.css"

const map = () => {
  return (
    <div className='map-container'>
      <div className="map-top">
        <h3>The Strathcona motel is conveniently located off Highway 17,
             in the friendly residential area of Current River 
             in Thunder Bay.</h3>
      </div>
      <div className="map-bottom">
        <div className="map-left">
            <iframe width="600" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=strathcona%20motel&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
        <div className="map-right">
            <h2>The City Of <span>Thunder Bay</span> Has So Much To See!</h2>
            <div className="sights-gallery">
              <div className="sights-falls"></div>
              <div className="sights-fw"></div>
              <div className="sights-marina"></div>
            </div>
            <button><a href="/places">View Sights</a></button>
            <div className="map-right-content">
              <p>You can start your day across the street at the beautiful
                Strathcona Golf Course.
              </p>
              <p>
                Stop in for a bite at the Beefcake Burger Factory,
                The Hodder Greeks Resteraunt, or Angelos Pizza and Sub. 
                Then pop in for a drink at The Hodder Tavern. All conveniently located within 
                a 3 minute walk of the motel!
             </p>
            </div>
            
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1662348907">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
    </div>
  )
}

export default map

import React from 'react'

import StrathconaFront from "../../assets/Strathcona-Front.jpg"

import "./Discount.css"
import "./DiscountMobile.css"

const discount = () => {
  return (
    <div className='discount-container'>
     <div className="discount-top">
        <h2>We Offer <span>Discounted</span> Rates</h2>
        <h4>Inquire About Our Special Rates When you Book Your Stay!</h4>
     </div>
     <div className="discount-bottom">
        <div className="discount-left">
            <h3>Special Rates Offered for Contractors and Extended Stay Guests!</h3>
            <h4>***MENTION THIS AD WHEN YOU CALL TO BOOK***</h4>
            <p>Discounted rates are available to all Contract workers and extended stay guests
                with a booking for 1 or more weeks.
            </p>
            <button><a href="/booking">Book Now</a></button>
        </div>
        <div className="discount-right">
            <div className="discount-image">
                
            </div>
            
        </div>
     </div>
     <div class="custom-shape-divider-bottom-1662165651">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
     </div>
        
    </div>
  )
}

export default discount

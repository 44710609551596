import React from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';

import './OurStory.css';
import './OurStoryMobile.css';

AOS.init();

const OurStory = () => {
  return (
    <div className='our-story-container'>
      <div className="our-story-top">
        <h2>Our Story</h2>
      </div>
      <div className="our-story-bottom">
        <div className="our-story-card" id='beginning' data-aos="fade-down-right" data-aos-duration="1000" data-aos-easing="ease-in-out" >
            <h2>1977</h2>
            <h3>The <span>Beginning</span></h3>
            <p>As a young girl, I would often accompany my mother while she worked her cleaning jobs at a few of our local businesses. 
                Who would have thought that I was playing in the backyard of the Strathcona 
                Motel while my mother was working as a housekeeper back then. I never thought
                 I would be owning this very same motel one day!
            </p>
        </div>
        <div className="our-story-card" id='starting' data-aos="fade-down-left" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="1000">
            <h2>2018</h2>
            <h3>At the <span>Starting</span> Gate</h3>
            <p>
                Uriel and I started our hospitality business with our Airbnb's on Mapleward Rd (Thunder Bay). We got started, and we loved it! We 
                truly enjoyed meeting people from across Canada, and around the world. We were so pleased
                to provide them with a unique and comfortable stay. Our Cozy Cottages come fully stocked 
                 with amenities, including breakfast! On a cold winters day, our guests enjoy cozying up 
                with a netflix film, or a nice dinner in front of the wood fire place.
            </p>
            <p>
                This has even been a little holiday escape for many of our local Thunder Bay guests as well!
            </p>
            <p>
                Learn more about our Cozy Cottages!     <br /><button><a href="/"></a>View Cottages</button>
            </p>
            
        </div>
        <div className="our-story-card" id='race' data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="ease-in-out">
            <h2>2019</h2>
            <h3>In the <span>Race</span></h3>
            <p>
                My husband and I purchased the motel this year, and we were pleased to have our motel in the same neighbourhood where we raised our children. 
                This Motel has a lot of history, and has been a family run business for generations before us. We hope to carry on this legacy!
            </p>
        </div>
        <div className="our-story-card" id='onwards' data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="ease-in-out">
            <h2>2021</h2>
            <h3><span>Onwards</span> and <span>Upwards</span></h3>
            <p>
                Now that things are beginning to open back up again, we are happy to welcome travellers back to our Motel and Cozy Cottage's.
            </p>
            <br />
            <p>
                With Covid-19 we are taking great protocol to keep our guests and our family safe. We have implemented many new guidelines , and take pride in always providing our
                guests with a clean and comfortable place to stay.
            </p>
        </div>
       
      </div>
       <div className="our-story-banner">
        
        </div>
    </div>
  )
}

export default OurStory

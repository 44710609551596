import React from 'react'
import { useState } from 'react'

import "./Nav.css"
import "./NavMobile.css"

const Nav = () => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='nav-container'>
      <div className="nav-content">
        <div className="nav-logo">
            <h2><a href="">Strathcona Motel</a></h2>
        </div>
        <div className={`nav-links ${isOpen && "open"}`}>
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/booking">Book A Room</a></li>
                <li><a href="/about">About Us</a></li>
                <li><a href="/places">Places To See</a></li>
                <li><a href="/contact">Contact Us</a></li>
              
            </ul>
        </div>
        
      </div>
      <div className={`nav-mobile ${isOpen && "open"}`}
       onClick={() => setIsOpen(!isOpen)}>
          <div className="bars"></div>
        </div>
    </div>
  )
}

export default Nav

import React from 'react'

import Navbar from '../components/Nav/Nav'
import Booking from '../components/Booking/Booking'
import Footer from '../components/Footer/Footer'

const BookingPage = () => {
  return (
    <div>
      <Navbar />
        <Booking />
      <Footer />
    </div>
  )
}

export default BookingPage

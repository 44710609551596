import React from 'react'

import "./Hours.css"
import "./HoursMobile.css"

const Hours = () => {
  return (
    <div className='hours-container'>
      <div className="hours-top">
        <div className="hours-left">
            <h2>Our Hours:</h2>
            <div className="hour-times">
                <h3>Monday-Saturday:</h3>
                <h4>8AM - 1AM</h4>
                <h3>Sunday:</h3>
                <h4>11Am - 8Pm</h4>   
            </div>
        </div>
        <div className="hours-right">
            <h3>Come In, We're Open</h3>
            <h4>We want to hear from you!</h4>
            <button><a href="/contact">Contact Us</a></button>
        </div>
      </div>
      <div className="hours-bottom">

      </div>
    </div>
  )
}

export default Hours

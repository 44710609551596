
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';

import { BsFillTelephoneFill, BsPhoneFill } from 'react-icons/bs'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { AiOutlineFieldTime } from 'react-icons/ai'

import "./Contact.css"
import "./ContactMobile.css"



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xudhzrq', 'template_ask41g4', form.current, 'LMJIs-uhnyDorKqW6')
    .then((result) => {
      console.log(result.text);
      }, (error) => {
          console.log(error.text);
    });
    e.target.reset();
  };


  return (
    <div className='contact-container'>
      <div className="contact-top">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-bottom">
        <div className="contact-left">
          <h2>We're Here To Help</h2>
          <div className="strath-phone">
            <h3><BsFillTelephoneFill /> Phone Number:</h3>
            <a href=''>1 (807) 683 8136</a>
            <h3><BsFillTelephoneFill /> Toll Free:</h3>
            <a>1 (888) 242 3716</a>
            <h3><BsPhoneFill />Text Line:</h3>
            <h4>1 (807) 683-8285</h4>
          </div>
          <div className="strath-email">
            <h3><MdEmail /> Email:</h3>
            <h4>strathcona@tbaytel.net</h4>
          </div>
          <div className="strath-address">
            <h3><MdLocationOn /> Address:</h3>
            <p>Address 545 Hodder Ave, <br /> Thunder Bay, ON, <br /> P7A 1V7 </p>
          </div>
          <div className="strath-hours">
            <h3><AiOutlineFieldTime /> Hours:</h3>
            <p>Mon - Sat: 8am to 1am <br /> Sun: 11am to 8pm</p>
          </div>
          
        </div>
        <div className="contact-right">
            <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input 
              type="text" 
              name="user_name" 
              id='name'
            />
            <label>Email</label>
            <input
              type="email" 
              name="user_email"
              id='email'
            />
            <label>Subject</label>
            <input 
              type="text" 
              name="subject"
              id='subject' 
            />
            <label>Message</label>
            <textarea 
              name="message" 
              id='message'
            />
           <input type="submit" value="Send" className='submit-button' />

            </form>
        </div>
      </div>
    </div>
  )
}

export default Contact




import React from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';

import './AboutUsBanner.css'
import './AboutUsBannerMobile.css'

AOS.init();

const AboutUsBanner = () => {
  return (
    <div className='banner-container'>
        <div className="banner-blur">
            
      </div> 
        <div className="banner-content">
            <h1>Strathcona Motel</h1>
            <h2>Home Away. Stay With Us.</h2>
        </div>
      
    </div>
  )
}

export default AboutUsBanner

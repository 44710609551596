import React from 'react'
import TbayPlaces from '../../assets/Tbay-Places.mov';

import CentennialPark from '../../assets/CentennialPark.jpg'
import BoulevardLake from '../../assets/BoulevardLake.jpg'
import Marina from '../../assets/Marina.jpg'
import FortWilliam from '../../assets/FortWilliam.jpg'
import Kekabekka from '../../assets/Kekabekka.jpg'
import PlacesBackground from '../../assets/Strathcona-Places-Background.jpg'

import "./Places.css"
import './PlacesMobile.css'

const Places = () => {
  return (
    <div className='places-container'>
        <div className="background-vid">
            <img src={ PlacesBackground} alt="" />
        </div>
      
      <div className="places-top">
        <h1>See All The Beauty Thunder Bay Has To Offer</h1>
      </div>
      <div className="places-bottom">
        <div className="centennial">
            <img src={CentennialPark} alt="" />
            <div className="centennial-info">
                <h2>Centennial Park</h2>
                <h5>2.5km</h5> 
                <p>Centennial Park is a large park area that follows the shores 
                    of the rocky Current River. It features recreational facilities, 
                    such as trails, an animal farm, and a toboggan hill.</p> 
            </div>   
        </div>

        <div className="boulevard">
            <img src={BoulevardLake} alt="" />
            <div className="boulevard-info">
                <h2>Boulevard Lake</h2>
                <h5>2.9km</h5>
                <p>Boulevard Lake is a small lake in Current River. It features a sandy beach,
                    mini putt, a walking trail, and a newly built inflated water park.
                </p>   
            </div>
        </div>

        <div className="marina">
            <img src={Marina} alt="" />
            <div className="marina-info">
                <h2>Marina Park</h2>
                <h5>5.5km</h5>
                <p>Marina Park features walking paths and a boardwalk, playground equipment, 
                    picnic tables, a Mariner's Monument[1] and the historic CN Rail 
                    Station 
                </p>
            </div>
        </div>

        <div className="oldfort">
            <img src={FortWilliam} alt="" />
            <div className="oldfort-info">
                <h2>Fort William Historical Park</h2>
                <h5>25km</h5>
                <p>Fort William Historical Park is a Canadian historical site 
                    that contains a reconstruction of the Fort William fur trade 
                    post as it existed in 1816.</p>
            </div>
        </div>

        <div className="kekabekka">
            <img src={ Kekabekka } alt="" />
            <div className="kekabekka-info">
                <h2>Kekabekka Falls</h2>
                <h5>40km</h5>
                <p>
                    Kekabekka Falls is the second highest waterfall in Ontario with excellent
                    views from the boardwalk. It also features walking and nature trails in the summer and 
                    groomed cross-country ski trails in the winter.
                </p>
            </div>
        </div>

      </div>
    </div>
  )
}

export default Places

import React from 'react'

import HeroPage from "../components/Hero/Hero"
import Navbar from "../components/Nav/Nav"
import PriorityPage from "../components/Priorities/Priorities"
import DiscountPage from "../components/Discount/Discount"
import MapPage from "../components/Map/Map"
import HoursPage from "../components/Hours/Hours"
import Footer from "../components/Footer/Footer"

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <HeroPage />
      <PriorityPage />
      <DiscountPage />
      <MapPage />
      <HoursPage />
      <Footer />
    </div>
  )
}

export default HomePage


import React from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';

import './KnowUs.css'
import './KnowUsMobile.css'

AOS.init();


const KnowUs = () => {
  return (
    <div className='know-us-container'>
      <div className="know-us-top">
        <h2>Get To Know Us</h2>
      </div>
      <div className="know-us-bottom">
        <div className="know-us-left">
            <div className="know-us-img"></div>
        </div>
        <div className="know-us-right">
            <h3>Irene & Uriel Hines</h3>
            <p>
                It was our lifelong dream to own a family business,
                and in October of 2019 our dream came true!
            </p>
            <br />
            <p>
                Meet my husband Uriel and I, we both immigrated to Canada
                from the warmer climates of Jamaica and Croatia. We fell in love
                with Thunder Bay for its local beauty, activities, and small town feel.
            </p>
            <br />
            <p>
                We raised our four children in the same neighbourhood as The Strathcona Motel.
                Current River is a friendly and sweet residential area with a homey feel,
                the perfect place to stay while travelling.
            </p>
            <br />
            <p>
                There is no need to travel far, as the motel is only a 3 short minute walk to many
                amenities (Skaf's Grocery Store, Angelo's Pizza & Sub, Shoppers Drug Mart, Robin's
                Donuts, Beefcakes Burger Factory, Circle K Gas Station, and the Hodder Tavern).
            </p>
            <br />
            <p>
                We truly hope you enjoy yourself at one of our Home Away's.
            </p>
        </div>
      </div>
    </div>
  )
}

export default KnowUs

import React from 'react'

import { MdFacebook } from 'react-icons/md'


import "./Footer.css"
import "./FooterMobile.css"

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className="footer-top">
        <h2>Check us out on Facebook!</h2>
        <button><a href="https://www.facebook.com/profile.php?id=100076719123055" target='_blank'><MdFacebook /></a></button>
       
      </div>
      <div className="footer-bottom">
        <h2><p>&copy; Copyright 2021 - Strathcona Motel.</p> </h2>
        <h3>Developed by Rory Deck</h3>
      </div>
    </div>
  )
}

export default Footer

import React, { useRef } from "react";
import emailjs from '@emailjs/browser';

import "./Booking.css";
import "./BookingMobile.css";

import StrathconaKitchenImg from '../../assets/Strathcona-Kitchen.jpg'
import StrathconaLivingImg from '../../assets/Strathcona-LivingRoom.jpg'
import StrathconaBathImg from '../../assets/Strathcona-Bathroom.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const Booking = () => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_xudhzrq', 'template_tnpjvwd', form.current, 'LMJIs-uhnyDorKqW6')
      .then((result) => {
        console.log(result.text);
        }, (error) => {
            console.log(error.text);
      });
      e.target.reset();
    };

  return (
    <div className="booking-container">
      <div className="booking-top">
        <h1>Book Your Stay With Us Today!</h1>
      </div>
      <form ref={form} onSubmit={sendEmail}>
      <div className="reservation-heading">
        <h2>Booking Enquiry Form</h2>  
      </div>  
      <div className="reservation-info">        
        <label htmlFor="name">Full Name
        <input type="text" name="user-name" id="name" required />
        </label>
        <label htmlFor="phone">Phone Number
        <input type="tel" name="user-phone" id="phone" required />
        </label>
        <label htmlFor="email"> Email
            <input type="email" name="user-email" id="email" required />
        </label>
      </div>
      
      <div className="date-selector">
        <label htmlFor="Arrival">
          Check In Date
          <input type="date" name="user-arrival" required/>
        </label>
        <label htmlFor="Departure">
          Check Out Date
          <input type="date" name="user-departure" required/>
        </label>
        <label htmlFor="RoomOptions">
          Select Room
          <select name="user-room" id="rooms" required>
            <option value="">**Select A Room**</option>
            <option value="deluxe">Deluxe</option>
            <option value="superior">Superior</option>
          </select>
        </label>
    
      </div>
      <div className="booking-submit">
        <input type="submit" value="Send"className="submit-button" ></input>
    
        <h3>Fill out the information above for the dates you are interested in booking, and we will contact you soon!</h3>
      </div>
      </form>
      <div className="booking-bottom">
        <div class="custom-shape-divider-top-1663457781">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="booking-left">
            <div className="booking-deluxe">
                <h2><span>Deluxe</span> Room</h2>
                <h3>All Deluxe Rooms Inlclude:</h3>
                <ul>
                  <li>Double bed</li>
                  <li>Couch (pull-out)</li>
                  <li>Kitchenette (dishes, cutlery, cooking utensils, pots/pans)</li>
                  <li>Fridge, Stove/Oven, Microwave, Coffee station, Dining table</li>
                  <li>Flat Screen TV (cable)</li>
                  <li>Black out shades, Hair dryer, Alarm clock</li>
                  <li>Air conditioning, Air exchange</li>
                  <li>Free Wifi</li>
                </ul>
            </div>
            <div className="booking-superior">
                <h2><span>Superior</span> Room</h2>
                <h3>All Superior Rooms Include:</h3>
                <ul>
                  <li>Queen bed</li>
                  <li>Kitchenette (dishes, cutlery, cooking utensils, pots/pans)</li>
                  <li>Fridge, Stove/Oven, Microwave, Coffee station, Dining area</li>
                  <li>Flat Screen TV (cable)</li>
                  <li>Black out shades, Hair dryer, Alarm clock</li>
                  <li>Air conditioning, Air exchange</li>
                  <li>Free Wifi</li>
                </ul>
                
            </div>

        </div>
        <div className="booking-right">
            <div className="image-kitchen" data-aos="flip-left">
                <img src={ StrathconaKitchenImg } alt=""  data-aos-duration="1500"/>
                <h4>Fully Stocked Kitchenettes</h4>
            </div>
            <div className="image-dinning" data-aos="flip-right">
                <h4>Plenty of Cozy Living Space</h4>
                <img src={ StrathconaLivingImg } alt=""  data-aos-duration="1500"/>
            </div>
            <div className="image-bath" data-aos="flip-left" >
                <img src={ StrathconaBathImg } alt=""  data-aos-duration="1500"/>
                <h4>Clean and Tidy Bathrooms</h4>
            </div>
            
        </div>
      </div>
    </div>
  );
};

export default Booking;
